import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Flexbox } from '@/components/flex';
import { breakpoints } from '@/components/responsive';

export interface TabSelectProps {
  defaultValue?: string;
  tabs: string[];
  disabledTabs?: string[];
  tabWidth?: number;
  tabHeight?: number;
  tabRadius?: number;
  tabPadding?: string;
  tabFont?: string;
  containerPadding?: number;
  onTabSelected?: (idx: number, name: string) => void;
  selectedColor?: string;
  unselectedColor?: string;
  className?: string;
}

export function TabSelect({
  tabs,
  disabledTabs = [],
  tabWidth = 10,
  tabHeight = 5,
  tabRadius = 10,
  tabPadding = `0`,
  tabFont = `JetBrains Mono, serif`,
  containerPadding = 0.5,
  onTabSelected,
  defaultValue,
  selectedColor,
  unselectedColor,
  className,
}: TabSelectProps) {
  const [selectedTab, setSelectedTab] = useState({
    idx: Math.max(
      tabs.findIndex((tab) => tab === defaultValue),
      0,
    ),
    name: defaultValue || tabs[0],
  });
  const selectorRef = useRef(null);

  const onTabSelect = (item: string) => () => {
    const idx = tabs.indexOf(item);
    const left = containerPadding + idx * tabWidth;
    if (selectorRef.current) {
      selectorRef.current.style.left = `${left}rem`;
    }
    setSelectedTab({ idx, name: item });
    if (onTabSelected) onTabSelected(idx, item);
  };

  useEffect(() => {
    if (selectorRef.current) {
      const idx = tabs.indexOf(defaultValue);
      const left = containerPadding + idx * tabWidth;
      if (selectorRef.current) {
        selectorRef.current.style.left = `${left}rem`;
      }
      setSelectedTab({ idx, name: defaultValue });
    }
  }, [selectorRef.current]);

  useEffect(() => {
    onTabSelect(defaultValue);
  }, [defaultValue]);

  return (
    <TabContainer
      className={`tab-select-container ${className}`}
      direction="row"
      radius={tabRadius}
      padding={containerPadding}
    >
      {tabs.map((item) => (
        <Tab
          key={item}
          className={`tab-select-tab tab-${item}`}
          height={tabHeight}
          width={tabWidth}
          selected={selectedTab.name === item}
          onClick={onTabSelect(item)}
          padding={tabPadding}
          disabled={disabledTabs.includes(item)}
          font={tabFont}
          selectedColor={selectedColor}
          unselectedColor={unselectedColor}
        >
          {item}
        </Tab>
      ))}
      <Selector
        className="tab-select-selector"
        ref={selectorRef}
        height={tabHeight}
        width={tabWidth}
        radius={tabRadius}
      />
    </TabContainer>
  );
}

const TabContainer = styled(Flexbox)`
  && {
    width: max-content;
    position: relative;
    padding: ${(props: { radius: number; padding: number }) =>
      props.padding}rem;
    flex-direction: row;
    background: #f3f5f7;
    border-radius: ${(props: { radius: number; padding: number }) =>
      props.radius}rem;
    z-index: 20;
  }
`;

const Tab = styled(Flexbox)`
  && {
    width: ${(props) => props.width}rem;
    height: ${(props) => props.height}rem;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.font};
    font-size: 1.1rem;
    font-weight: ${(props) => (props.selected ? `900` : `500`)};
    color: ${(props) =>
      props.selected
        ? props.selectedColor || `black`
        : props.unselectedColor || `#A6AFB8`};
    user-select: none;
    cursor: pointer;
    z-index: 25;
    transition: color, 0.3s linear;
    text-align: center;
    padding: ${(props) => props.padding};
    pointer-events: ${(props) => (props.disabled ? `none` : `auto`)};
    text-decoration: ${(props) => (props.disabled ? `line-through` : `none`)};
    opacity: ${(props) => (props.disabled ? `0.5` : `1`)};
  }
  ${breakpoints.up(`md`)} {
    :hover {
      color: black;
    }
  }
`;

const Selector = styled.div`
  && {
    display: block;
    position: absolute;
    left: 0.5rem;
    width: ${(props) => props.width}rem;
    height: ${(props) => props.height}rem;
    background-color: white;
    border: 0.13rem solid rgba(211, 216, 229, 0.37);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.16);
    border-radius: ${(props) => props.radius}rem;
    z-index: 22;
    transition: left ease-in-out 0.5s;
  }
`;

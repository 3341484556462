import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import './rich-text-css-settings.css';
import {
  CustomToolbarRichText,
  modules,
} from '@/features/editor/widgets/custom-widget/inputs/typography/custom-toolbar-rich-text';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { maybe } from '@/features/details/utils';
import {
  fontFamilyOptions,
  usualAttributes,
  bugStyleVersion,
} from '@/utils/rich-text-utils';
import { AltTextTooltip } from '@/features/editor/widgets/custom-widget/inputs/typography/edit-icon';
import { Customization } from '@/webapi/use-widget-catalog-api';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

const VALUE_KEY = `defaultAttributes`;

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;
export interface RichTextEditorProps {
  defaultValue: string;
  onChange: (ev: any) => void;
  ref: any;
  spec: any;
  handleAiAltText: () => void;
  loading: boolean;
  idToolbar?: string;
  device: DeviceType;
  customization: Customization;
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void;
}
export function RichTextEditor({
  defaultValue,
  ref,
  spec,
  idToolbar = `toolbar-header-0`,
  onValuesChanged,
  onChange,
  handleAiAltText,
  loading,
  device,
  customization,
}: RichTextEditorProps) {
  const [updatedFonts, setUpdatedFonts] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [colorValue, setColorValue] = useState(usualAttributes.color);
  const [colorArray, setColorArray] = useState([]);
  const [fontSizeValue, setFontSizeValue] = useState(usualAttributes.size);
  const [letterSpacingValue, setLetterSpacingValue] = useState(
    usualAttributes.letterSpacing,
  );
  const [lineHeightValue, setLineHeightValue] = useState(
    usualAttributes.lineHeight,
  );
  const [textShadowValue, setTextShadowValue] = useState(
    usualAttributes.textShadow,
  );
  const [textLength, setTextLength] = useState(0);
  const editorRef = useRef(null);
  const parentRef = useRef(null);

  const editorCtx = useContext(EditorContext);
  const installedFonts = useMemo(() => {
    if (editorCtx?.devicePreview?.editorState?.installedFonts) {
      return editorCtx?.devicePreview?.editorState?.installedFonts;
    }
    return [];
  }, [editorCtx]);

  const { currentSchema, currentWidget } = useContext(CustomWidgetContext);

  useEffect(() => {
    const defaultFonts = fontFamilyOptions.filter(
      (font) =>
        !installedFonts?.some(
          (option) => option.toLowerCase() === font.toLowerCase(),
        ),
    );
    const updated = [...installedFonts, ...defaultFonts];
    setUpdatedFonts(updated);
  }, [installedFonts]);

  useEffect(() => {
    if (editorRef?.current) {
      const quill = editorRef.current.getEditor();
      const clearBackgroundColor = () => {
        const length = quill.getLength();
        quill.formatText(0, length, { background: false });
      };
      const handleSelectionChange = (range) => {
        if (
          !!range &&
          checkRangeValue(range.index) &&
          checkRangeValue(range.length)
        ) {
          clearBackgroundColor();
          if (range.length > 0) quill.format(`background`, `#B4D7FE`);
          const format = quill.getFormat(
            range.length > 0 ? range.index + 1 : range.index,
          );
          const letterSpacing =
            format.letterSpacing || usualAttributes.letterSpacing;
          const fontSize = format.size || `13px`;
          const color = format.color || `#000000`;
          const lineHeight = format.lineHeight || usualAttributes.lineHeight;
          const textShadow = format.textShadow || usualAttributes.textShadow;
          setLineHeightValue(lineHeight);
          setLetterSpacingValue(letterSpacing);
          setFontSizeValue(fontSize);
          setColorValue(color);
          setTextShadowValue(textShadow);
        }
      };
      // Test without delay
      // const delayedSelectionChange = (range: any) => {
      //   setTimeout(() => handleSelectionChange(range), 100);
      // };
      quill.on(`selection-change`, handleSelectionChange);

      quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
        const currentFormat = quill.getFormat();
        delta.ops.forEach((op) => {
          op.attributes = {
            ...currentFormat,
          };
        });

        return delta;
      });

      return () => {
        clearBackgroundColor();
        quill?.off(`selection-change`, handleSelectionChange);
      };
    }
    return null;
  }, [isRendered]);

  useEffect(() => {
    const styleElement = document.createElement(`style`);
    document.head.appendChild(styleElement);
    updatedFonts.forEach((font) => {
      const sanitizedFont = font.replaceAll(`"`, `'`);
      styleElement.sheet.insertRule(
        `.ql-toolbar .ql-font span[data-label="${sanitizedFont}"]::before { font-family: ${sanitizedFont}; }`,
        styleElement.sheet.cssRules.length,
      );
    });
    if (editorRef?.current) {
      const editor = editorRef.current.getEditor();
      if (editor) {
        const delta = editor.getContents();
        const attributes =
          maybe(() => spec?.values?.defaultAttributes) || usualAttributes;
        const firstOp = delta.ops.find(
          (el: { insert: string }) =>
            !!el.insert && el.insert.trim().length > 0,
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { bold, background, ...restAttributes } =
          firstOp?.attributes || {};

        if (
          !!firstOp &&
          !areSpecificKeysEqual(restAttributes, attributes) &&
          bugStyleVersion[currentWidget?.id] !== currentWidget?.version
        ) {
          setTimeout(() => {
            const length = editor.getLength();
            editor.formatText(0, length, {
              ...attributes,
              align: attributes.align || null,
            });
          }, 500);
        }
      }
    }

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isRendered, updatedFonts]);

  function onQuillChange(val) {
    onChange(encodeURIComponent(val));
  }

  const [isAiCopy, setIsAiCopy] = useState(false);
  useEffect(() => {
    if (editorRef?.current) {
      const editor = editorRef.current.getEditor();
      if (editor) {
        const delta = editor.getContents();
        const length = editor.getLength();
        if (length !== textLength) {
          editor.formatText(0, Math.max(length, textLength), {
            background: false,
          });
        }
        setTextLength(length);
        if (!isAiCopy) {
          let firstOp = null;
          let index = 0;
          const colorSet: Set<string> = new Set();
          delta.ops.forEach((op: { insert: string | any; attributes: any }) => {
            const color = op.attributes?.color || `#000000`;
            colorSet.add(color);
            if (!firstOp) {
              if (!!op.insert && op.insert?.trim().length > 0) {
                firstOp = op;
                return;
              }
              index +=
                typeof op.insert === `string` && op.insert.length > 0
                  ? op.insert.length
                  : 1;
            }
          });
          setColorArray(Array.from(colorSet));
          const inlineFormat = editor.getFormat(index, index + 1);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { background, ...restInlineFormat } = inlineFormat;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { align, bold, ...restUsualAttributes } = usualAttributes;
          const firstFormat = firstOp
            ? {
                ...restUsualAttributes,
                ...restInlineFormat,
              }
            : { ...usualAttributes };

          onValuesChanged(VALUE_KEY, firstFormat, DeviceType.Mobile);
        } else {
          const attributes =
            maybe(() => spec?.values?.defaultAttributes) || usualAttributes;
          editor.formatText(0, length, {
            ...attributes,
            align: attributes.align || null,
          });
          setIsAiCopy(true);
        }

        const attributes = usualAttributes;
        if (delta.ops.length === 1) {
          delta.ops.forEach((op) => {
            if (op.insert.trim() !== ``) return;
            op.attributes = {
              ...attributes,
              ...op.attributes,
            };
            editor.insertText(0, `\uFEFF`, op.attributes);
            editor.setSelection(0);
          });
        }
        const range = editor.getSelection();
        const format = range ? editor.getFormat() : usualAttributes;
        const letterSpacing =
          format.letterSpacing || usualAttributes.letterSpacing;
        const fontSize = format.size || `13px`;
        const color = format.color || `#000000`;
        const lineHeight = format.lineHeight || usualAttributes.lineHeight;
        const textShadow = format.textShadow || usualAttributes.textShadow;
        setLineHeightValue(lineHeight);
        setLetterSpacingValue(letterSpacing);
        setFontSizeValue(fontSize);
        setColorValue(color);
        setTextShadowValue(textShadow);
      }
    }
  }, [spec.values.value]);

  const backgroundColor = useMemo(() => {
    const getBackgroundValue = (components, containerKey, device) => {
      const container = components?.find((e) => e.key === containerKey);
      const backgroundSpec = container?.specs?.find(
        (e) => e.key === `background`,
      )?.values;
      return device === DeviceType.Mobile
        ? backgroundSpec?.value
        : backgroundSpec?.desktop?.value || backgroundSpec?.value;
    };

    const option1 = getBackgroundValue(
      customization?.components,
      `container`,
      device,
    );
    const option2 = getBackgroundValue(
      currentSchema?.customizations?.find((e) => e.key === `container`)
        ?.components,
      `style`,
      device,
    );
    const option3 = getBackgroundValue(
      currentSchema?.customizations?.find((e) => e.key === `blockStyle`)
        ?.components,
      `container`,
      device,
    );

    const result = option1 || option2 || option3;

    return typeof result === `string` &&
      (result?.startsWith(`rgb`) || result?.startsWith(`#`))
      ? result
      : null;
  }, [currentSchema?.customizations]);

  // Solution for non-resettable scrollToIntoView mode in our environment
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (isRendered) {
      setTimeout(() => {
        setReadOnly(false);
      }, 1500);
    }
  }, [isRendered]);

  const defaultDecodedValue = useMemo(() => {
    try {
      return decodeURIComponent(defaultValue);
    } catch (_) {
      return defaultValue;
    }
  }, [defaultValue]);

  // START LVP-5024
  // function onMouseEnter() {
  //   const editor = editorRef?.current?.getEditor();
  //   if (editor) {
  //     editor.focus();
  //     const selection = editor.getSelection();
  //     const length = editor.getLength();
  //     if (selection?.length === 0) {
  //       editor.setSelection(0, length);
  //     }
  //   }
  // }
  //
  // function onMouseOut(e) {
  //   if (!e.currentTarget.contains(e.relatedTarget)) {
  //     if (document.querySelector(`.react-tiny-popover-container`)) return;
  //     const editor = editorRef?.current?.getEditor();
  //     if (editor) {
  //       const length = editor.getLength();
  //       editor.setSelection(0);
  //       editor.formatText(0, length, { background: false });
  //     }
  //   }
  // }
  // END LVP-5024

  return (
    updatedFonts.length > 0 && (
      <Wrapper
        // LVP-5024
        // onMouseEnter={() => onMouseEnter()}
        // onMouseOut={(e) => onMouseOut(e)}
        ref={ref}
        className="text-editor"
      >
        <CustomToolbarRichText
          isRendered={isRendered}
          setIsRendered={setIsRendered}
          updatedFonts={updatedFonts}
          setColorValue={setColorValue}
          colorValue={colorValue}
          fontSizeValue={fontSizeValue}
          setFontSizeValue={setFontSizeValue}
          letterSpacingValue={letterSpacingValue}
          setLetterSpacingValue={setLetterSpacingValue}
          textShadowValue={textShadowValue}
          lineHeightValue={lineHeightValue}
          setLineHeightValue={setLineHeightValue}
          editorRef={editorRef}
          spec={spec}
          readOnly={readOnly}
          idToolbar={idToolbar}
        />
        {isRendered && (
          <TextFieldWrapper
            ref={parentRef}
            backgroundColor={backgroundColor}
            isBlackTheme={shouldUseBlackTheme(colorArray)}
          >
            <ReactQuill
              readOnly={readOnly}
              value={defaultDecodedValue}
              ref={editorRef}
              onChange={(val: string) => onQuillChange(val)}
              modules={{
                ...modules,
                toolbar: {
                  ...modules?.toolbar,
                  container: `#${idToolbar}`,
                },
              }}
              placeholder="Change me"
            />
            {!spec?.noAi && (
              <div
                style={{
                  position: `absolute`,
                  top: `12px`,
                  right: `5px`,
                }}
              >
                <AltTextTooltip
                  loading={loading}
                  onClick={() => {
                    setIsAiCopy(true);
                    return handleAiAltText();
                  }}
                />
              </div>
            )}
          </TextFieldWrapper>
        )}
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background: white;
  margin-bottom: 0.4rem;
  border-radius: 10px !important;
`;

const TextFieldWrapper = styled.div`
  position: relative;
  border-radius: 0 0 10px 10px;
  background: ${(props: {
    isBlackTheme: boolean;
    backgroundColor: string | null;
  }) =>
    restrictAlphaZero(props.backgroundColor) ||
    (props.isBlackTheme ? `#333333` : null)};
`;

function restrictAlphaZero(color: string | null) {
  return color?.split(`,`)[3]?.trim().startsWith(`0`) ? null : color;
}

function checkRangeValue(v) {
  return typeof v === `number` && v >= 0;
}

function areSpecificKeysEqual(obj1, obj2) {
  const keys = Object.keys(obj1);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

function shouldUseBlackTheme(colorArray: string[]) {
  if (!colorArray || !(colorArray.length > 0)) return false;

  function hexToRgb(hex) {
    let r;
    let g;
    let b;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7 || hex.length === 9) {
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    } else {
      return null;
    }
    return { r, g, b };
  }

  function rgbToRGB(rgb) {
    const values = rgb.match(/\d+/g).map(Number);
    return { r: values[0], g: values[1], b: values[2] };
  }

  function getBrightness(r, g, b) {
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  let result = false;
  colorArray.forEach((color) => {
    let rgb;

    if (color.startsWith(`rgb`)) {
      rgb = rgbToRGB(color);
    } else if (color.startsWith(`#`)) {
      rgb = hexToRgb(color);
    }

    if (rgb) {
      const brightness = getBrightness(rgb.r, rgb.g, rgb.b);
      if (brightness > 200) result = true;
    }
  });

  return result;
}

export function formatNumber(number: number) {
  if (typeof number !== `number`) {
    return ``;
  }
  if (Number.isNaN(number)) {
    return `0`;
  }

  if (Math.abs(number) >= 1000000) {
    const millions = number / 1000000;
    return `${millions % 1 === 0 ? millions : millions.toFixed(1)}M`;
  }
  if (Math.abs(number) >= 1000) {
    const thousands = number / 1000;
    return `${thousands % 1 === 0 ? thousands : thousands.toFixed(1)}K`;
  }
  return number.toString();
}

const formatter = new Intl.NumberFormat(`en-US`);

export function formatNumberWithCommas(number: number): string {
  return formatter.format(number);
}

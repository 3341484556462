import { IoEyeOutline } from 'react-icons/io5';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Experience } from '@/webapi/use-experience-api';
import { HSpace, VSpace } from '@/components/spacing';
import { Anchor } from '@/features/editor/widgets/theme-test/shared';
import { EditorContext } from '@/features/editor/context/editor-context';

const GreenDot = styled.span`
  background: #2cdc55;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
const Original = styled.div`
  font-family: Inter, serif;
  color: #b1bac1;
  font-size: 1.4rem;
  font-weight: 600;
`;
const LiveTheme = styled.div`
  font-family: Inter, serif;
  display: flex;
  justify-content: start;
  align-items: center;
  color: #7b8995;

  span {
    max-width: 14vw;
    @media (max-width: 1200px) {
      max-width: 10vw;
    }
    @media (max-width: 1000px) {
      max-width: 8vw;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
const calcControlAlloc = (currentExperience: Experience) =>
  100 - (currentExperience?.variants?.reduce((p, c) => p + c.chance, 0) || 0);
const ThemeSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 2rem 1.7rem 2rem 2.65rem;
  border-radius: 15px;
  background: #f2f4f6;
`;

export function OriginalTheme() {
  const {
    resources,
    experienceState: { currentExperience, selectTheme },
  } = useContext(EditorContext);
  const mainTheme = resources?.themes?.find((theme) => theme.role === `main`);
  const isEnabled = currentExperience.themeId === mainTheme.id;
  return (
    <ThemeSelectWrapper>
      <FlexCol>
        <Original>Original</Original>
        <VSpace />
        <LiveTheme>
          <GreenDot />
          <span>Theme: ({mainTheme?.name || ``})</span>
        </LiveTheme>
      </FlexCol>
      <FlexRow>
        <Anchor onClick={() => selectTheme(mainTheme.id)}>
          <IoEyeOutline size={24} color={isEnabled ? `black` : `#aeafb1`} />
        </Anchor>
        <HSpace value={2} />
        <ControlChance>{calcControlAlloc(currentExperience)}%</ControlChance>
      </FlexRow>
    </ThemeSelectWrapper>
  );
}

const FlexRow = styled.div`
  align-items: center;
  justify-content: start;
  display: flex;
`;
const ControlChance = styled.div`
  background: white;
  width: 7rem;
  height: 4.5rem;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  font-weight: 500;
  font-family: Inter, serif;
  font-size: 1.5rem;
  color: #bec8ce;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;
